<template>
  <span :class="cssClass">CHF&nbsp;{{ display }}</span>
</template>
<script>
export default {
  name: 'CurrencyValue',
  props: {
    value: { type: Number, default: 0 },
    limit: { type: Number, default: 0 },
  },
  data() {
    return {
      modelValue: this.value,
      decimalSeparator: '.',
      thousandsSeparator: "'",
    };
  },
  computed: {
    cssClass() {
      return this.modelValue < this.limit ? 'red--text' : '';
    },
    display() {
      let v = this.modelValue;
      v = v.toLocaleString('de-CH');
      if (
        v.length !== 1 &&
        v.slice(v.indexOf(this.decimalSeparator) + 1).length === 1
      )
        v += '0';
      else if (v.indexOf(this.decimalSeparator) == -1) {
        v += '.00';
      }
      return v;
    },
  },
  watch: {
    value() {
      this.modelValue = this.value;
    },
  },
};
</script>
